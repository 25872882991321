#contact {
    background-color: #282c34;

    color: #fff;

    text-align: left;

    position: relative;

    padding-top: 70px;

    min-height: 800px;

}

#contact .arrow-right {
    width: 200px;
    height: 200px;
    background: url(../../images/triangle-right-green.svg);
    background-size: 100%;
    position: absolute;
    left: -90px;
    top: 10px;
}

@media (max-width: 919px) {

    #contact .arrow-right {
        width: 120px;
        height: 120px;
        background: url(../../images/triangle-right-green.svg);
        background-size: 100%;
        position: absolute;
        left: -40px;
        top: 40px;
    }

}