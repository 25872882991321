#carbon-footprint{
    padding-top: 100px;

    min-height: 850px;

    background-color: #F0ECD3;
}

#carbon-footprint .arrow-right {
    width: 200px;
    height: 200px;
    background: url(../../images/triangle-right-green.svg);
    background-size: 100%;
    position: absolute;
    left: -90px;
    top: 120px;
}

#carbon-footprint #copyright{
    color: #fff;
}

#carbon-footprint #footprint-calculator .progress{
    position: absolute;
    top: 10%;
    left: 50%;
}

#carbon-footprint #footprint-calculator iframe{
    z-index: 100;
}

@media (max-width: 919px) {

    #carbon-footprint .arrow-right {
        width: 120px;
        height: 120px;
        background: url(../../images/triangle-right-green.svg);
        background-size: 100%;
        position: absolute;
        left: -40px;
        top: 160px;
    }

}

@media (max-width: 425px) {

    #carbon-footprint .title {
        font-size: 2rem;
    }

}