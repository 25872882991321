#who-we-are {
    background-color: #282c34;

    color: #fff;

    text-align: left;

    position: relative;

    min-height: 600px;
}

#who-we-are .header {
    background-color: #282c34;

    color: #fff;

    text-align: left;

    position: relative;

    min-height: 100px;
}

#who-we-are .content {

    color: #fff;

    text-align: left;

    position: relative;

    min-height: 700px;

    padding-top: 100px;

    padding-bottom: 100px;

}

#who-we-are .arrow-right {
    width: 200px;
    height: 200px;
    background: url(../../images/triangle-right-blue.svg);
    background-size: 100%;
    position: absolute;
    left: -90px;
    top: -10px;
}

@media (max-width: 919px) {

    #who-we-are .arrow-right {
        width: 120px;
        height: 120px;
        background: url(../../images/triangle-right-blue.svg);
        background-size: 100%;
        position: absolute;
        left: -40px;
        top: 20px;
    }

}